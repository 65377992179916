/* Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 100vw; Full width */
    background-color: rgba(236, 236, 236, 0.562); /* Deep Purple */
    padding: 0 20px 0 0;
    color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.nav-links {
    display: flex;
    gap: 0;
}

.nav-links a {
    color: rgb(230, 0, 234);
    text-decoration: none;
    /* font-weight: 500; */
    font-size: 16px;
    padding: 20px 15px;
    transition: background-color 0.3s ease;
}

.nav-links a:hover {
    background-color: rgba(138, 177, 214, 0.2);
}

.logout-btn {
    background-color: rgb(255, 118, 45);
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.logout-btn:hover {
    background-color: darkred;
}
