/* Loading.css */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full screen height */
    background-color: none; /* Clean background */
    color: rgb(63, 25, 139);
    font-size: 18px;
    font-weight: bold;
}

/* The animated spinner */
.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(63, 25, 139, 0.3);
    border-top: 5px solid rgb(63, 25, 139);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
